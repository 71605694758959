import React from "react"

import Layout from "../components/layout"
import Img from "gatsby-image"
import moment from "moment"
import SEO from "../components/seo"
import Typography from "../components/typography"
import renderHTML from "react-render-html"
import SelectableList from "../components/selectableList"
import { Base } from "../utils/animations"

import "./how-it-works.css"
import { Link } from "gatsby"

const gridItems = [
  {
    icon: require("../images/icons/grid01.svg"),
    header: "Honest inputs for honest results.",
    desc:
      "The more we know, the better we can help our clients navigate upcoming hurdles and events. We will work to our utmost ability to keep an eyes wide open approach to your road ahead.",
  },
  {
    icon: require("../images/icons/grid02.svg"),
    header: "A sense of calm in the chaotic loan world.",
    desc:
      "We understand the amount of stress brought on by personal finance and the daunting task of paying off student loans.  We will work to eliminate those feelings for each client.",
  },
  {
    icon: require("../images/icons/grid03.svg"),
    header: "Tailored to your goals, but also your comfort level.",
    desc:
      "No two people are the same, so no two plans are the same.  Beyond the financials, we will assess your cash flow tolerance and financial behavior to ensure your plan will work for your lifestyle.",
  },
  {
    icon: require("../images/icons/grid04.svg"),
    header: "Building a foundation for your long-term success.",
    desc:
      "No matter the timeframe, having a comprehensive plan will always yield the highest rate of success. We will ensure your plan has you on track for the best possible long-term success.",
  },
]

const GridSection = () => {
  return (
    <section className="how__grid-section">
      <div className="page-wrapper">
        <Typography variant="h2">
          Paying off student debt to find a financial peace of mind
        </Typography>
        <Typography
          variant="body1"
          style={{ opacity: 0.6, marginTop: 12, maxWidth: 670 }}
        >
          Personal finance continually ranks as one of the top stressors in US
          households. Student loan debt only increases this issue. Our plans are
          built to give you a path to financial freedom.
        </Typography>
        <div className="how__grid">
          {gridItems.map(item => (
            <div key={item.header} className="how__grid-item">
              <div className="how__grid-container">
                <img src={item.icon} alt="icon" className="how__grid-icon" />
                <Typography variant="h3">{item.header}</Typography>
                <Typography variant="body1">{item.desc}</Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const IndexPage = ({ data: { meta, posts } }) => (
  <Layout>
    <SEO
      slug={meta.slug}
      title={meta.yoast_meta.yoast_wpseo_title}
      description={meta.yoast_meta.yoast_wpseo_metadesc}
    />
    <section className="how__hero ">
      <div className="how__container page-wrapper">
        <div className="how__content">
          <Typography variant="h1">
            Break free from student loans and start living life on your terms
          </Typography>
          <Typography
            variant="body1"
            style={{ opacity: 0.6, marginTop: 12, marginBottom: 48 }}
          >
            We want you to achieve all of your financial goals, not only your
            goal of paying off your student loans. Our planning encompasses your
            entire financial picture and emphasizes long-term success.
          </Typography>
        </div>
      </div>
      <img
        src={require("../images/hero-bg.svg")}
        alt=""
        className="how__hero-bg"
      />
    </section>
    <SelectableList className="how__list" />

    <GridSection />

    <section className="recent__posts page-wrapper">
      <div className="news__grid ">
        {posts.edges.map(({ node }) => (
          <Base key={node.slug} className="post">
            <Link to={`/blog/${node.slug}`}>
              <div className="post__image-container">
                {node.acf.cover_image_post && (
                  <Img
                    fluid={
                      node.acf.cover_image_post.localFile.childImageSharp.fluid
                    }
                    alt={renderHTML(node.acf.cover_image_post.alt_text)}
                  />
                )}
              </div>
              <div className="post__content">
                <Typography style={{ color: "#000" }} variant="h3">
                  {renderHTML(node.title)}
                </Typography>
                <Typography variant="body1" style={{ color: "#000" }}>
                  {moment(node.date).format("MMMM Do, YYYY")}
                </Typography>
              </div>
            </Link>
          </Base>
        ))}
      </div>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    meta: wordpressPage(slug: { eq: "how-it-works" }) {
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    posts: allWordpressPost(limit: 3) {
      edges {
        node {
          slug
          title
          author {
            name
          }
          date
          categories {
            name
          }
          acf {
            cover_image_post {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
